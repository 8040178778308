/* eslint-disable */
/* eslint-disable import/no-unresolved */
import { FC, useContext, useState } from "react";
import { ChevronDown } from "react-feather";
import { CustomerContext, UserContext } from "src/config/userContext";
import { Dropdown, Tooltip, MenuProps, Row } from "antd";
import "./styles.css";
import { useTranslation } from "react-i18next";
import { DownOutlined } from "@ant-design/icons";

interface CustomerDropdownProps {
  collapsed?: boolean;
}

const CustomerDropdown: FC<CustomerDropdownProps> = ({ collapsed }) => {
  const { user } = useContext(UserContext);
  const { customer, customerUpdate } = useContext(CustomerContext);
  const { t } = useTranslation();
  const [tooltipVisible, setTooltipVisible] = useState(false);

  const handleClick = (customer1: any) => {
    customerUpdate(customer1);
    const stringCust = JSON.stringify(customer1);
    sessionStorage.setItem("customerSelected", stringCust);
  };

  const customerMenuItems: MenuProps["items"] = [
    {
      key: "account-header",
      label: (
        <span style={{ color: "#FFFFFF", marginBottom: 8 }}>
          {t("account").toUpperCase()}
        </span>
      ),
      type: "group", // Opcional: lo puedes usar para semántica de grupo en el menú
    },
    ...user?.customers?.map((customer2: any) => ({
      key: customer2.id,
      label: (
        <div
          className={`avatar-name-wrapper ${
            customer2.id === customer.id ? "selected" : ""
          }`}
        >
          <div className="custom-avatar">
            {customer2?.name ? customer2.name.charAt(0).toUpperCase() : "?"}
          </div>
          <span
            onClick={() => handleClick(customer2)}
            style={{
              fontSize: "14px",
              display: "block",
              textAlign: "left",
              borderRadius: collapsed ? "8px" : "4px",
              height: "auto",
              margin: "0",
              justifyContent: collapsed ? "space-between" : "flex-start",
              alignItems: collapsed ? "center" : "initial",
              width: "100%",
              cursor: "pointer",
              color: "white",
            }}
          >
            {customer2?.name}
          </span>
        </div>
      ),
    })),
  ];
  

  const menu = { items: customerMenuItems, className: "custom-dropdown-menu" };

  const MAX_LENGTH = 14;

  const getCustomerDisplayName = (name: string) => {
    if (!name) return "";
    return name.length > MAX_LENGTH ? name.slice(0, MAX_LENGTH) + "..." : name;
  };

  const getCustomerInitial = (name: string) => {
    return name ? name.charAt(0).toUpperCase() : "";
  };

  return (
    <Dropdown menu={menu} trigger={["click"]}>
      <Tooltip title={customer?.name || customer?.id} open={tooltipVisible} onOpenChange={(open) => setTooltipVisible(open)}>
        <div className={`${collapsed ? "collapsed-customer-dropdown" : "default-customer-dropdown"}`} onClick={() => setTooltipVisible(false)}>
          {collapsed ? getCustomerInitial(customer?.name || customer?.id) : getCustomerDisplayName(customer?.name || customer?.id)}
          {!collapsed && (
            <svg width="15" height="14" viewBox="0 0 15 14" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M7.59259 9.96743C7.16049 9.96743 6.72839 9.81958 6.40123 9.52934L2.37654 5.95886C2.19753 5.80005 2.19753 5.53719 2.37654 5.37838C2.55555 5.21957 2.85185 5.21957 3.03086 5.37838L7.05555 8.94886C7.35185 9.21172 7.83333 9.21172 8.12963 8.94886L12.1543 5.37838C12.3333 5.21957 12.6296 5.21957 12.8086 5.37838C12.9877 5.53719 12.9877 5.80005 12.8086 5.95886L8.78395 9.52934C8.45679 9.81958 8.02469 9.96743 7.59259 9.96743Z"
                fill="white"
              />
            </svg>
          )}
        </div>
      </Tooltip>
    </Dropdown>
  );
};

export default CustomerDropdown;
